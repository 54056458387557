"use client";

import { useEffect } from "react";

import CookieCleanup from "@/components/layout/CookieCleanup";
import { MobileAppPrompt } from "@/components/MobileAppPrompt/MobileAppPrompt";
import { MyGworkEventsWP } from "@/src/components/events/MyGworkEventsWP";
import { FriendlyFeaturedOrganizations } from "@/src/components/homepage/FriendlyFeaturedOrganizations";
import { HeroSection } from "@/src/components/homepage/HeroSection";
import { OurPartnerOrganizations } from "@/src/components/homepage/OurPartnerOrganizations";
import { StickyCta } from "@/src/components/homepage/StickyCta";
import { VideoSection } from "@/src/components/homepage/VideoSection";
import { WhatWeOffer } from "@/src/components/homepage/WhatWeOffer";
import { Container } from "@/src/components/layout/Container";
import Footer from "@/src/components/layout/Footer";
import Navbar from "@/src/components/layout/Navbar";
import { MyGworkNewsWP } from "@/src/components/news/MyGworkNewsWP";
import { withPublic } from "@/src/hoc/withPublic";
import { useAppGetHomePageData } from "@/src/hooks/api/generated";
import { useAuth } from "@/src/hooks/useAuth";
import { getSocket } from "@/src/socket";

function PublicHomePage(): JSX.Element {
  const { data } = useAppGetHomePageData();
  const socket = getSocket();
  const { authData } = useAuth();

  useEffect(() => {
    const handleConnect = (): void => {
      if (authData && socket) {
        socket.emit("subscribe_to_user_conversations_list", {
          userId: authData.id,
        });
      }
    };
    if (authData && socket) {
      socket.on("connect", handleConnect);
    }
    // Clean up the listeners
    return () => {
      if (socket) {
        socket.off("connect", handleConnect);
      }
    };
  }, [authData, socket]);

  return (
    <div>
      <MobileAppPrompt />
      <CookieCleanup />
      <MobileAppPrompt />
      <Navbar />
      <Container>
        <div className="my-5 lg:my-20 flex flex-col gap-y-10 lg:gap-y-20">
          <HeroSection />
          <VideoSection />
          <WhatWeOffer />
          <OurPartnerOrganizations />
          {!!data?.friendlyOrganizations.length && (
            <FriendlyFeaturedOrganizations
              organization={data.friendlyOrganizations[0]}
            />
          )}
          {!!data?.latestNews.length && (
            <MyGworkNewsWP title="myGwork News" news={data.latestNews} />
          )}
          {!!data?.upcomingEvents.length && (
            <MyGworkEventsWP
              title="myGwork Events"
              events={data?.upcomingEvents}
            />
          )}
        </div>
      </Container>
      <Footer />
      <StickyCta />
    </div>
  );
}

export default withPublic(PublicHomePage);
